import React from "react";

export default function WordPopup(props) {
  const [word, setWord] = React.useState(props.wordsArr[0]);
  const [wordsArr, setWordsArr] = React.useState(props.wordsArr);
  const [lettersArr, setLettersArr] = React.useState([]);
  const [currentWordIndex, setCurrentWordIndex] = React.useState(0);
  const [utter, setUtter] = React.useState();
  const [isUpperCase, setIsUpperCase] = React.useState(false);

  const getNextWord = () => setCurrentWordIndex(currentWordIndex + 1);
  const getPrevWord = () => setCurrentWordIndex(currentWordIndex - 1);

  React.useEffect(() => {
    props.isOpen && setUtter(new SpeechSynthesisUtterance());
  }, [props.isOpen]);

  React.useEffect(() => {
    setLettersArr((word || "").split(""));
    if (utter) {
      utter.text = word;
      window.speechSynthesis.speak(utter);
    }
  }, [utter, word]);

  React.useEffect(() => {
    setWord(wordsArr[currentWordIndex]);
  }, [currentWordIndex, wordsArr]);

  const textToSpeech = (text) => {
    utter.text = text;
    window.speechSynthesis.speak(utter);
  };

  const utterWord = () => {
    textToSpeech(word);
  };

  const utterLetter = (e) => {
    textToSpeech(e.target.innerText);
  };

  const switchCase = () => {
    isUpperCase
      ? setWordsArr(wordsArr.map((word) => word.toLowerCase()))
      : setWordsArr(wordsArr.map((word) => word.toUpperCase()));
    setIsUpperCase(!isUpperCase);
  };

  return (
    <section className={props.isOpen ? "" : "hide"}>
      <div className="overlay overlay_opacity_darker"></div>
      <div className="form">
        <ul className="form__word-content">
          {lettersArr.map((letter, i) => (
            <li className="form__letter-content" key={i} onClick={utterLetter}>
              {letter}
            </li>
          ))}
        </ul>
        <div className="form__card-number">
          <p>
            {props.gradeLevel} ({currentWordIndex + 1} of{" "}
            {props.wordsArr.length})
          </p>
        </div>
        <button
          type="button"
          className="form__close-button"
          onClick={props.onClose}
        ></button>
        <div className="form__controls-container">
          <div
            className={
              currentWordIndex > 0
                ? "form__next-button"
                : "form__next-button hide"
            }
            aria-label="Previous Word"
            onClick={getPrevWord}
          >
            ← Prev Word
          </div>
          <div>
            <span
              className="form__speak-button"
              role="img"
              aria-label="Speak Word"
              onClick={utterWord}
            >
              🗣️
            </span>
          </div>
          <div
            className={
              currentWordIndex < props.wordsArr.length - 1
                ? "form__next-button"
                : "form__next-button hide"
            }
            aria-label="Next Word"
            onClick={getNextWord}
          >
            Next Word →
          </div>
        </div>
        <div className="form__controls-container_place_top">
          <div
            className="form__next-button"
            aria-label="Switch case"
            onClick={switchCase}
          >
            {isUpperCase ? "Switch to Lower Case" : "Switch to Upper Case"}
          </div>
        </div>
      </div>
    </section>
  );
}
