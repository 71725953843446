import React from "react";
import {
  preKDolchSightWords,
  kDolchSightWords,
  firstGradeDolchSightWords,
  secondGradeDolchSightWords,
  thirdGradeDolchSightWords,
  PRE_K,
  KINDERGARTEN,
  FIRST_GRADE,
  SECOND_GRADE,
  THIRD_GRADE,
} from "../../utils/Constants";
import { randomizeArray } from "../../utils/utils";

const WithDolchSightWords = (WrappedComponent, gradeLevel) => {
  let wordsArr = [];
  switch (gradeLevel) {
    case PRE_K:
      wordsArr = randomizeArray(preKDolchSightWords);
      break;
    case KINDERGARTEN:
      wordsArr = randomizeArray(kDolchSightWords);
      break;
    case FIRST_GRADE:
      wordsArr = randomizeArray(firstGradeDolchSightWords);
      break;
    case SECOND_GRADE:
      wordsArr = randomizeArray(secondGradeDolchSightWords);
      break;
    case THIRD_GRADE:
      wordsArr = randomizeArray(thirdGradeDolchSightWords);
      break;
    default:
      wordsArr = randomizeArray(preKDolchSightWords);
  }
  return (props) => (
    <WrappedComponent {...props} wordsArr={wordsArr} gradeLevel={gradeLevel} />
  );
};

export default WithDolchSightWords;
