import React from "react";
import { Route, NavLink, useRouteMatch } from "react-router-dom";
import {
  PRE_K,
  KINDERGARTEN,
  FIRST_GRADE,
  SECOND_GRADE,
  THIRD_GRADE,
} from "../../utils/Constants";
import WithDolchSightWords from "./withDolchSightWords";
import WordPopup from "../WordPopup";
import ReactGA from "react-ga";

function SightWords() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { path, url } = useRouteMatch();
  const [isWordPopupOpen, setIsWordPopupOpen] = React.useState(false);

  const closeAllPopups = () => {
    if (isWordPopupOpen) {
      setIsWordPopupOpen(false);
    }
  };

  const handleLinkClick = () => {
    setIsWordPopupOpen(true);
  };

  const PreKWordPopupWithDolchSightWords = WithDolchSightWords(
    WordPopup,
    PRE_K
  );
  const KinderWordPopupWithDolchSightWords = WithDolchSightWords(
    WordPopup,
    KINDERGARTEN
  );
  const FirstGradeWordPopupWithDolchSightWords = WithDolchSightWords(
    WordPopup,
    FIRST_GRADE
  );
  const SecondGradeWordPopupWithDolchSightWords = WithDolchSightWords(
    WordPopup,
    SECOND_GRADE
  );
  const ThirdGradeWordPopupWithDolchSightWords = WithDolchSightWords(
    WordPopup,
    THIRD_GRADE
  );

  return (
    <div className="page__content">
      <p className="page__text page__text_att_margin-top">
        Sight words are words that do not fit standard phonetic patterns and
        must be memorized. Two of the most popular sources are the Dolch Sight
        Words list and the Fry Sight Words list.
      </p>

      <ul className="menu menu_placement_body">
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/pre-k-sightwords`}
            onClick={handleLinkClick}
          >
            Pre-K Dolch Sight Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/kindergarten-sightwords`}
            onClick={handleLinkClick}
          >
            Kindergarten Dolch Sight Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/first-grade-sightwords`}
            onClick={handleLinkClick}
          >
            First Grade Dolch Sight Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/second-grade-sightwords`}
            onClick={handleLinkClick}
          >
            Second Grade Dolch Sight Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/third-grade-sightwords`}
            onClick={handleLinkClick}
          >
            Third Grade Dolch Sight Words →
          </NavLink>
        </li>
      </ul>
      <Route path={`${path}/pre-k-sightwords`}>
        <PreKWordPopupWithDolchSightWords
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/kindergarten-sightwords`}>
        <KinderWordPopupWithDolchSightWords
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/first-grade-sightwords`}>
        <FirstGradeWordPopupWithDolchSightWords
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/second-grade-sightwords`}>
        <SecondGradeWordPopupWithDolchSightWords
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/third-grade-sightwords`}>
        <ThirdGradeWordPopupWithDolchSightWords
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
    </div>
  );
}

export default SightWords;
