import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import CommonWords from "./sight-words/CommonWords";
import Home from "./Home";
import SightWords from "./sight-words/SightWords";

import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("UA-122225880-2", {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="page">
      <Header />
      <main className="content">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/high-frequency-words">
            <CommonWords />
          </Route>
          <Route path="/sight-words">
            <SightWords />
          </Route>
        </Switch>
      </main>
      <Footer />
    </div>
  );
}

export default App;
