import React from "react";

function Logo() {
  return (
    <p className="header__logo">
      <span
        role="img"
        aria-label="Flashcard logo"
        className="header__logo_bolt"
      >
        ⚡
      </span>
      LEARN SIGHT WORDS
    </p>
  );
}

export default Logo;
