import React from "react";
import NavBar from "./NavBar";
import Logo from "./Logo";

export default function Header(props) {
  return (
    <header className="header">
      <Logo />
      <NavBar />
    </header>
  );
}
