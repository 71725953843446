import React from "react";
import learningImg from "../images/learning.jpg";
const Home = () => {
  return (
    <>
      <div className="page__content">
        <img className="page__banner" src={learningImg} alt="learning" />
        <h2
          className="page__text 
          page__text_att_h2
        page__text_att_margin-top 
        "
        >
          Hey there, welcome to this site.
        </h2>
        <p>
          I'm a mom and I have a little one that I'm teaching how to read. I've
          tried several flashcard apps but I haven't found anything that fits in
          terms of what I like them to look like or behave like.
        </p>
        <p>
          I also happen to be a web developer and I thought, why not just create
          one myself? So here it is, currently a simple flash cards web app for
          a collection of sight words. I plan to add more (maybe some word
          games) later on when I find the time.
        </p>
        <p>
          I hope this will be a useful teaching tool for you and your little
          one. Enjoy!
        </p>
        <h3
          className="page__text 
          page__text_att_h3
        page__text_att_margin-top 
        "
        >
          UPDATES:
        </h3>
        <p className="page__text_att_update">
          <span className="page__text_att_date">21 Sep 2020:</span> You can now
          switch to display the words in upper case or back to lower case (which
          is the default). Please note that in upper case mode, the speech
          synthesizer does not pronounce some words correctly, example "IN" is
          pronounced "I-N".
        </p>
      </div>
    </>
  );
};

export default Home;
