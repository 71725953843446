import React from "react";
import { Route, NavLink, useRouteMatch } from "react-router-dom";
import WithFrySightWords from "./withFrySightWords";
import WordPopup from "../WordPopup";
import ReactGA from "react-ga";

const CommonWords = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { path, url } = useRouteMatch();
  const [isWordPopupOpen, setIsWordPopupOpen] = React.useState(false);

  const closeAllPopups = () => {
    if (isWordPopupOpen) {
      setIsWordPopupOpen(false);
    }
  };

  const handleLinkClick = () => {
    setIsWordPopupOpen(true);
  };

  const WordPopupWithFrySightWords100 = WithFrySightWords(WordPopup, "100");
  const WordPopupWithFrySightWords200 = WithFrySightWords(WordPopup, "200");
  const WordPopupWithFrySightWords300 = WithFrySightWords(WordPopup, "300");

  return (
    <div className="page__content">
      <p className="page__text page__text_att_margin-top">
        High-frequency words are the most commonly used words in printed text
        and over 50 percent of all text is composed of them.
      </p>

      <blockquote
        className="page__blockquote"
        cite="https://sightwords.com/sight-words/fry/"
      >
        <p>
          The Fry Sight Words list is a more modern list of words than the Dolch
          list, and was extended to capture the most common 1,000 words. Dr.
          Edward Fry developed this expanded list in the 1950s (and updated it
          in 1980), based on the most common words to appear in reading
          materials used in Grades 3-9. Learning all 1,000 words in the Fry list
          would equip a child to read about 90% of the words in a typical book,
          newspaper, or website.
        </p>

        <cite>
          -{" "}
          <a
            href="https://sightwords.com/sight-words/fry/"
            target="_blank"
            rel="noopener noreferrer"
            className="page__cite"
          >
            https://sightwords.com/sight-words/fry/
          </a>
        </cite>
      </blockquote>

      <ul className="menu menu_placement_body">
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/fry-first-100`}
            onClick={handleLinkClick}
          >
            1st 100 Fry Most Common Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/fry-second-100`}
            onClick={handleLinkClick}
          >
            2nd 100 Fry Most Common Words →
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu__link"
            to={`${url}/fry-third-100`}
            onClick={handleLinkClick}
          >
            3rd 100 Fry Most Common Words →
          </NavLink>
        </li>
      </ul>
      <Route path={`${path}/fry-first-100`}>
        <WordPopupWithFrySightWords100
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/fry-second-100`}>
        <WordPopupWithFrySightWords200
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
      <Route path={`${path}/fry-third-100`}>
        <WordPopupWithFrySightWords300
          isOpen={isWordPopupOpen}
          onClose={closeAllPopups}
        />
      </Route>
    </div>
  );
};

export default CommonWords;
