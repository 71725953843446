export const PRE_K = "Pre-K";
export const KINDERGARTEN = "Kindergarten";
export const FIRST_GRADE = "First Grade";
export const SECOND_GRADE = "Second Grade";
export const THIRD_GRADE = "Third Grade";

export const preKDolchSightWords = [
  "a",
  "and",
  "away",
  "big",
  "blue",
  "can",
  "come",
  "down",
  "find",
  "for",
  "funny",
  "go",
  "help",
  "here",
  "I",
  "in",
  "is",
  "it",
  "jump",
  "little",
  "look",
  "make",
  "me",
  "my",
  "not",
  "one",
  "play",
  "red",
  "run",
  "said",
  "see",
  "the",
  "three",
  "to",
  "two",
  "up",
  "we",
  "where",
  "yellow",
  "you",
];

export const kDolchSightWords = [
  "all",
  "am",
  "are",
  "at",
  "ate",
  "be",
  "black",
  "brown",
  "but",
  "came",
  "did",
  "do",
  "eat",
  "four",
  "get",
  "good",
  "have",
  "he",
  "into",
  "like",
  "must",
  "new",
  "no",
  "now",
  "on",
  "our",
  "out",
  "please",
  "pretty",
  "ran",
  "ride",
  "saw",
  "say",
  "she",
  "so",
  "soon",
  "that",
  "there",
  "they",
  "this",
  "too",
  "under",
  "want",
  "was",
  "well",
  "went",
  "what",
  "white",
  "who",
  "will",
  "with",
  "yes",
];

export const firstGradeDolchSightWords = [
  "after",
  "again",
  "an",
  "any",
  "as",
  "ask",
  "by",
  "could",
  "every",
  "fly",
  "from",
  "give",
  "going",
  "had",
  "has",
  "her",
  "him",
  "his",
  "how",
  "just",
  "know",
  "let",
  "live",
  "may",
  "of",
  "old",
  "once",
  "open",
  "over",
  "put",
  "round",
  "some",
  "stop",
  "take",
  "thank",
  "them",
  "then",
  "think",
  "walk",
  "were",
  "when",
];

export const secondGradeDolchSightWords = [
  "always",
  "around",
  "because",
  "been",
  "before",
  "best",
  "both",
  "buy",
  "call",
  "cold",
  "does",
  "don’t",
  "fast",
  "first",
  "five",
  "found",
  "gave",
  "goes",
  "green",
  "its",
  "made",
  "many",
  "off",
  "or",
  "pull",
  "read",
  "right",
  "sing",
  "sit",
  "sleep",
  "tell",
  "their",
  "these",
  "those",
  "upon",
  "us",
  "use",
  "very",
  "wash",
  "which",
  "why",
  "wish",
  "work",
  "would",
  "write",
  "your",
];

export const thirdGradeDolchSightWords = [
  "about",
  "better",
  "bring",
  "carry",
  "clean",
  "cut",
  "done",
  "draw",
  "drink",
  "eight",
  "fall",
  "far",
  "full",
  "got",
  "grow",
  "hold",
  "hot",
  "hurt",
  "if",
  "keep",
  "kind",
  "laugh",
  "light",
  "long",
  "much",
  "myself",
  "never",
  "only",
  "own",
  "pick",
  "seven",
  "shall",
  "show",
  "six",
  "small",
  "start",
  "ten",
  "today",
  "together",
  "try",
  "warm",
];

export const fryFirst100 = [
  "a",
  "about",
  "all",
  "an",
  "and",
  "are",
  "as",
  "at",
  "be",
  "been",
  "but",
  "by",
  "led",
  "can",
  "come",
  "could",
  "day",
  "did",
  "do",
  "down",
  "each",
  "find",
  "first",
  "for",
  "from",
  "get",
  "go",
  "had",
  "has",
  "have",
  "he",
  "her",
  "him",
  "his",
  "how",
  "I",
  "if",
  "in",
  "into",
  "is",
  "it",
  "like",
  "long",
  "look",
  "made",
  "make",
  "many",
  "may",
  "more",
  "my",
  "no",
  "not",
  "now",
  "number",
  "of",
  "oil",
  "on",
  "one",
  "or",
  "other",
  "out",
  "part",
  "people",
  "said",
  "see",
  "she",
  "sit",
  "so",
  "some",
  "than",
  "that",
  "the",
  "their",
  "them",
  "then",
  "there",
  "these",
  "they",
  "this",
  "time",
  "to",
  "two",
  "up",
  "use",
  "was",
  "water",
  "way",
  "we",
  "were",
  "what",
  "when",
  "which",
  "who",
  "will",
  "with",
  "words",
  "would",
  "write",
  "you",
  "your",
];

export const frySecond100 = [
  "after",
  "again",
  "air",
  "also",
  "America",
  "animal",
  "another",
  "answer",
  "any",
  "around",
  "ask",
  "away",
  "back",
  "because",
  "before",
  "big",
  "boy",
  "came",
  "change",
  "different",
  "does",
  "end",
  "even",
  "follow",
  "form",
  "found",
  "give",
  "good",
  "great",
  "hand",
  "help",
  "here",
  "home",
  "house",
  "just",
  "kind",
  "know",
  "land",
  "large",
  "learn",
  "letter",
  "line",
  "little",
  "live",
  "man",
  "me",
  "means",
  "men",
  "most",
  "mother",
  "move",
  "much",
  "must",
  "name",
  "need",
  "new",
  "off",
  "old",
  "only",
  "our",
  "over",
  "page",
  "picture",
  "place",
  "play",
  "point",
  "put",
  "read",
  "right",
  "same",
  "say",
  "sentence",
  "set",
  "should",
  "show",
  "small",
  "sound",
  "spell",
  "still",
  "study",
  "such",
  "take",
  "tell",
  "things",
  "think",
  "three",
  "through",
  "too",
  "try",
  "turn",
  "us",
  "very",
  "want",
  "well",
  "went",
  "where",
  "why",
  "work",
  "world",
  "years",
];

export const fryThird100 = [
  "above",
  "add",
  "almost",
  "along",
  "always",
  "began",
  "begin",
  "being",
  "below",
  "between",
  "book",
  "both",
  "car",
  "carry",
  "children",
  "city",
  "close",
  "country",
  "cut",
  "don’t",
  "earth",
  "eat",
  "enough",
  "every",
  "example",
  "eyes",
  "face",
  "family",
  "far",
  "father",
  "feet",
  "few",
  "food",
  "four",
  "girl",
  "got",
  "group",
  "grow",
  "hard",
  "head",
  "hear",
  "high",
  "idea",
  "important",
  "Indian",
  "it’s",
  "keep",
  "last",
  "late",
  "leave",
  "left",
  "let",
  "life",
  "light",
  "list",
  "might",
  "mile",
  "miss",
  "mountains",
  "near",
  "never",
  "next",
  "night",
  "often",
  "once",
  "open",
  "own",
  "paper",
  "plant",
  "real",
  "river",
  "run",
  "saw",
  "school",
  "sea",
  "second",
  "seem",
  "side",
  "something",
  "sometimes",
  "song",
  "soon",
  "start",
  "state",
  "stop",
  "story",
  "talk",
  "those",
  "thought",
  "together",
  "took",
  "tree",
  "under",
  "until",
  "walk",
  "watch",
  "while",
  "white",
  "without",
  "young",
];
