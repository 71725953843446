import React from "react";
import { fryFirst100, frySecond100, fryThird100 } from "../../utils/Constants";
import { randomizeArray } from "../../utils/utils";

const WithFrySightWords = (WrappedComponent, gradeLevel) => {
  let wordsArr = [];
  switch (gradeLevel) {
    case "100":
      wordsArr = randomizeArray(fryFirst100);
      break;
    case "200":
      wordsArr = randomizeArray(frySecond100);
      break;
    case "300":
      wordsArr = randomizeArray(fryThird100);
      break;
    default:
      wordsArr = randomizeArray(fryFirst100);
  }
  return (props) => <WrappedComponent {...props} wordsArr={wordsArr} />;
};

export default WithFrySightWords;
