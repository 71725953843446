export const randomizeArray = (arr = []) => {
  arr.forEach((item, i) => {
    const r = Math.floor(Math.random() * i);
    arr[i] = arr[r];
    arr[r] = item;
  });
  return arr;
};

export const getVoices = () => {
  return new Promise((resolve) => {
    let voices = speechSynthesis.getVoices();
    if (voices.length) {
      resolve(voices);
      return;
    }
    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices();
      resolve(voices);
    };
  });
};
