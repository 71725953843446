import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav>
      <ul className="menu">
        <li>
          <NavLink
            exact
            to="/"
            activeClassName="menu__link_active"
            className="menu__link"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sight-words"
            activeClassName="menu__link_active"
            className="menu__link"
          >
            Sight Words
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/high-frequency-words"
            activeClassName="menu__link_active"
            className="menu__link"
          >
            High-frequency Words
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
